import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../../../sections/Navbar/Navbar'
import Footer from '../../../sections/Footer'
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import "../../../styles/AdminPage.scss";

function MicrosoftCertification({ data }: any) {
    const info = data.prismicMicrosoft365Certification.dataRaw
    const infoImg = data.prismicMicrosoft365Certification.data
    return (
        <div>
            <Navbar />
            <div className='AdminPage'>
                <div className='AdminPage-title'>
                    <PrismicRichText field={info.title} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.updated} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte_1} />
                    <br />
                    <PrismicRichText field={info.texte_2}
                        components={{
                            hyperlink: ({ node, children, key }) => (
                                <PrismicLink
                                    field={node.data}
                                    className="link"
                                >
                                    {children}
                                </PrismicLink>
                            )
                        }}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MicrosoftCertification;

export const query = graphql`
query MicrosoftCertification {
  prismicMicrosoft365Certification(lang: {eq: "fr-fr"})  {
    dataRaw
    data {
      logo {
        alt
        url
      }
    }
  }
}
`